.site__navbar-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.site__navbar-icons img {
    width: 20px;
    height: auto;
    margin-right: 1rem;
    transition: filter 0.3s ease-in-out;
}

.site__navbar-icons img:hover {
    filter: brightness(0.7);
}