.site__capa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* justify-content: center;
    align-items: center; */
    flex: 1;
    height: 100vh;
    overflow: hidden;

    background-image: url('../../assets/capa.webp');
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.site__capa-content {
    padding-top: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.4;
    margin-right: 3rem;
    /* padding-top: 5rem; */
    padding-bottom: 1rem;

    font-family: var(--font-family-roboto);
}

.site__capa-content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.site__capa-content_text p,
.site__capa-content_text h3 {
    margin: 10px 0;
}

.site__capa-content_text h3 {
    font-family: var(--font-family-title);
}

.capa-nome,
.capa-sobre {
    font-size: 20px;
}

.capa-nome {
    text-decoration: underline;
}

.capa-nome {
    color: var(--color-font-capa);
}

.capa-sobre {
    color: var(--color-sobre-capa);
}

.site__capa-content_text h3 {
    font-size: 40px;
    color: var(--color-font-prof);
    /* text-shadow: 2px 2px 4px rgba(92, 92, 92, 0.5); */
    font-weight: 400;
    margin: 2rem 0;
}



.site__capa-content_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 3rem;
}

/* .site__capa-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.6;
}

.site__capa-image img {
    width: 400px;
    height: auto;
} */

/* @media screen and (max-width: 1200px) {
    .site__capa-image img {
        width: 350px;
    }
} */

/* @media screen and (max-width: 1155px) {
    .site__capa-image {
        display: none;
    }
} */

@media screen and (max-width: 800px) {
    .capa-nome,
    .capa-sobre {
        font-size: 18px;
    }

    .site__capa-content_text h3 {
        font-size: 33px;
    }
}

@media screen and (max-width: 600px) {
    .site__capa-content_text h3 {
        font-size: 27px;
    }

    .capa-nome,
    .capa-sobre {
        font-size: 16px;
    }

    .site__capa-content {
        margin: 0;
    }
}

@media screen and (max-width: 380px) {

    .site__capa-content_text h3 {
        font-size: 23px;
    }

    /* .site__capa-content_text p,
    .site__capa-content_text h3 {
        margin: 10px 0;
    } */
}

@media screen and (max-width: 320px) {
    .site__capa-content_text p,
    .site__capa-content_text h3 {
        margin: 5px 0;
    }
}

@media screen and (max-width: 280px) {
    .capa-nome,
    .capa-sobre {
        font-size: 14px;
    }

    .site__capa-content_text h3 {
        font-size: 20px;
    }
}

/* @media screen and (max-height: 575px) {
    .capa-nome,
    .capa-sobre {
        font-size: 14px;
    }

    .site__capa-content_text h3 {
        font-size: 20px;
    }

    .site__capa-image img {
        width: 350px;
    }

    .site__capa-content_text p,
    .site__capa-content_text h3 {
        margin: 7px 0;
    }
}

@media screen and (max-height: 525px) {
    .site__capa-content_text p,
    .site__capa-content_text h3 {
        margin: 4px 0;
    }

    .site__capa-content_text h3 {
        font-size: 16px;
    }
} */

@media screen and (max-height: 650px) {
    .site__capa {
        height: auto;
    }
}