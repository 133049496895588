.site__duvidas {
    /* background-color: var(--color-item); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.site__duvidas h2 {
    font-size: 40px;
    color: var(--color-font-prof);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-weight: 600;
    font-family: var(--font-family-title);
}

.site__duvidas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--color-font-navbar);
    /* border-left: 1px solid var(--color-dark);
    border-right: 1px solid var(--color-dark);
    border-bottom: 1px solid var(--color-dark);
    border-top: 1px solid var(--color-dark); */

    margin: 2rem 0;
}

@media screen and (max-width: 800px) {
    .site__duvidas h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .site__duvidas-btn button,
    .site__duvidas-btn svg {
        font-size: 18px;
    }
}

@media screen and (max-width: 450px) {
    .site__duvidas h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .site__duvidas-btn button,
    .site__duvidas-btn svg {
        font-size: 16px;
    }
}
