.site__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;   
}

.site__menu p {
    color: var(--color-font-navbar);
    font-family: var(--font-family-roboto);
    font-size: 16px;
    line-height: 16px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;

    border-top: 1px solid var(--color-font-navbar);
    padding-top: 10px;
}

.site__navbar-links_container p {
    position: relative;
    display: inline-block;
}

.site__navbar-links_container p::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 3px;
    background-color: var(--color-font-navbar);
    opacity: 0;
    margin-top: -2px;

    transition: width 0.3s ease;
    transform: translateX(-50%);
}

.site__navbar-links_container p:hover::before {
    width: 100%;
    opacity: 1;
}

@media screen and (max-width: 1175px) {
    .site__menu {
        flex-direction: column;
        align-items: flex-start;
    }

    .site__menu p {
        margin: 1.5rem 1rem;
        padding: 0;
        line-height: 0;
    }
}