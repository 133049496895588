.site__sessao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.site__sessao-titulo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.site__sessao-titulo h2 {
    font-size: 40px;
    color: var(--color-font-prof);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-weight: 600;
    font-family: var(--font-family-title);
}

.site__sessao-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 2rem 0;
}

.site__sessao-img img {
    width: 500px;
    border-radius: 15px;
}

.site__sessao-texto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    hyphens: auto;

    /* margin: 2rem 6rem; */
    background-color: var(--color-font-navbar);
    padding: 0 10px;
    border-radius: 10px;
    margin-right: 2rem;
}

.site__sessao-texto p {
    margin: 10px 0;
    font-size: 18px;
    color: var(--color-sobre-capa);
    font-family: var(--font-family-roboto);
}

.site__sessao-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .site__sessao-img img {
        width: 400px;
    }
}

@media screen and (max-width: 1050px) {
    .site__sessao-img {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .site__sessao-texto {
        margin: 2rem 0;
    }
}

@media screen and (max-width: 450px) {
    .site__sessao-titulo h2 {
        font-size: 30px;
    }
    .site__sessao-texto p {
        font-size: 16px;
    }

    .site__sessao-container {
        margin: 1rem 0;
    }
}
