.icone-dir {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 115px;
}

/* .icone-dir {
    margin-left: -57.5px;
} */