.site__rodape {
    overflow: hidden;
}

.site__rodape-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-bg);
    padding: 1.5rem;
}

.site__rodape-container_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.site__rodape-containter_content-logoEsq,
.site__rodape-containter_content-logoDir {
    display: flex;
    flex: 0.2;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.site__rodape-containter_content-logoEsq img {
    width: 100%;
}

.site__rodape-containter_content-logoDir img {
    width: 40%;
}

.site__rodape-container_content-links {
    display: flex;
    flex: 0.6;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.site__rodape-container_text {
    margin-top: 1rem;
}

.site__rodape-container_text a,
.site__rodape-container_text p {
    color: var(--color-font-prof);
    font-size: 16px;
    font-family: var(--font-family-title);
}

@media screen and (max-width: 550px) {

    .site__rodape-containter_content-logoEsq img,
    .site__rodape-containter_content-logoDir img {
        display: none;
    }

    .site__rodape-container_text a,
    .site__rodape-container_text p {
        font-size: 14px;
    }
}