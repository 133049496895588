.site__whatsBtn {
    border: 1px solid var(--color-sobre-capa);
    background-color: var(--color-1);
    padding: 4px;
    transition: background-color 0.3s ease;
}

.site__whatsBtn button {
    flex: 0.5;
    width: 100%;
    min-height: 35px;
    font-family: Hero Light Bold;
    font-size: 20px;
    /* border-radius: 5px; */
    cursor: pointer;
    border: 1px solid var(--color-sobre-capa);
    padding: 0 1rem;
    /* background: var(--color-1); */
    background: none;
    color: var(--color-sobre-capa);

    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-family: var(--font-family-roboto);

    transition: color 0.3s ease; /* Adicione uma transição para a cor do texto */
}

.site__whatsBtn:hover {
    background-color: var(--color-sobre-capa);
    border: 1px solid var(--color-1);
}

.site__whatsBtn:hover button {
    color: var(--color-1);
    border: 1px solid var(--color-1);
}

.site__whatsBtn-icon {
    margin-right: 0.5rem;
    vertical-align: middle;
    display: inline-flex;
    font-size: 26px;
    margin-left: 8px;
}

.btn-container {
    border: 1px solid var(--color-font-capa);
    margin: 4px;
}

/* @media screen and (max-width: 768px) {
    .site__whatsBtn button:hover {
        background-color: var(--color-2);
        color: white;
    }
} */

@media screen and (max-width: 800px) {
    .site__whatsBtn:hover {
        background-color: var(--color-1);
        border: 1px solid var(--color-sobre-capa);
    }
    
    .site__whatsBtn button:hover {
        color: var(--color-sobre-capa);
        border: 1px solid var(--color-sobre-capa);
    }
}

@media screen and (max-width: 550px) {
    .site__whatsBtn button {
        font-size: 14px;
    }

    .site__whatsBtn-icon {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {
    /* .site__whatsBtn button {
        width: 150px;
    } */

    .site__whatsBtn button {
        font-size: 13px;
    }

    .site__whatsBtn-icon {
        font-size: 13px;
    }
}

@media screen and (max-height: 525px) {
    .site__whatsBtn-icon {
        display: none;
    }
}