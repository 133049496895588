.site__prof {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.site__prof-titulo h1, h2 {
    text-align: center;
    font-family: var(--font-family-title);
    color: var(--color-font-prof);
}

.site__prof-titulo h1 {
    font-size: 52px;
    letter-spacing: 3px;
    border-bottom: 1px solid var(--color-font-prof);
    width: 500px;
    margin-bottom: 8px;
    /* text-transform: uppercase; */
}

.site__prof-titulo h2 {
    font-size: 36px;
}

.site__prof-titulo {
    margin-bottom: 4rem;
}

.linha-vertical {
    margin-top: 185px;
    width: 1px;
    background-color: var(--color-font-prof);
    height: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.item-esquerda {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 10rem;
}

.item-direita {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 10rem;
}

.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 0.5;
}

.icone-esq,
.icone-dir {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 115px;
    /* border: 1px solid white; */
}

.icone-esq {
    margin-left: -57.5px;
}

.item-esquerda-mobile {
    display: none;
}

@media screen and (max-width: 750px) {
    .linha-vertical {
        left: 15%;
        margin-top: 140px;
    }

    .icone-esq,
    .icone-dir {
        left: 15%;
        width: 85px;
        margin-bottom: 1.25rem;
    }

    .item-esquerda,
    .item-direita {
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .site__prof-titulo h1 {
        font-size: 30px;
        width: 250px;
    }
    
    .site__prof-titulo h2 {
        font-size: 20px;
    }

    .item-esquerda-mobile {
        display: flex;
    }

    .item-esquerda {
        display: none;
    }

    .icone-esq {
        margin-left: -43px;
    }
}

@media screen and (max-width: 330px) {
    .icone-esq,
    .icone-dir {
        width: 75px;
    }

    .icone-esq {
        margin-left: -38px;
    }
}