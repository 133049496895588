.slide-in-right {
    -webkit-animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left-menu {
    -webkit-animation: slide-in-left-menu 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left-menu 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-left-menu {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(300px);
        transform: translateX(300px);
        opacity: 0;
    }
}

@keyframes slide-in-left-menu {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(300px);
        transform: translateX(300px);
        opacity: 0;
    }
}