.site__especialidades {
    background-color: var(--color-fundo-secao);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border-right: 6rem solid var(--color-border-espec); */
}

.site__especialidades h2 {
    font-size: 40px;
    color: var(--color-bg);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-weight: 700;
    font-family: var(--font-family-title);
    margin-bottom: 3rem;
}

.site__especialidades-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.linha-inferior {
    margin-top: 1rem;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
}

.site__espec-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

/* @media screen and (max-width: 1175px) {
    .site__especialidades-container {
        gap: 10px;
    }
    
} */

@media screen and (max-width: 1150px) {
    /* .site__especialidades-container.linha-superior {
        grid-template-columns: repeat(2, 1fr);
    }

    .site__especialidades-container.linha-inferior {
        grid-template-columns: repeat(2, 1fr);
    }

    .site__especialidades-container.linha-inferior .ultimo-item {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
    } */

    .site__especialidades-container.linha-superior {
        grid-template-columns: 1fr;
    }

    .site__especialidades-container.linha-inferior {
        grid-template-columns: 1fr;
    }

    .linha-inferior {
        margin-top: 0;
    }

    .site__especialidades-container {
        gap: 0;
    }
}

@media screen and (max-width: 500px) {
    /* .site__especialidades-container.linha-superior {
        grid-template-columns: 1fr;
    }

    .site__especialidades-container.linha-inferior {
        grid-template-columns: 1fr;
    } */

    /* .site__especialidades-container {
        gap: 1rem;
    } */

    .site__especialidades h2 {
        font-size: 30px;
    }

    /* .site__especialidades {
        border-right: 2rem solid var(--color-border-espec);
    } */
}