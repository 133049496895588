.site__language {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-font-navbar);
    padding: 0 2px;
}

.site__language img {
    cursor: pointer;
    /* padding-left: 2px;
    padding-right: 2px; */
}

/* .site__language select {
    border: none;

    background-color: var(--color-font-navbar);
    color: var(--color-bg);
    font-family: var(--font-family-roboto);
    font-size: 17px;
    cursor: pointer;
} */

/* .selected-language {
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .language-select {
    outline: none;
} */

@media screen and (max-width: 350px) {
    .selected-language {
        display: none;
    }
}