* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

.section__padding {
    padding: 6rem 6rem;
}

.capa__padding {
    padding: 8rem 6rem;
}

@media screen and (max-width: 800px) {
    .section__padding {
        padding: 5rem 4rem;
    }

    .capa__padding {
        padding: 8rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 5rem 2rem;
    }

    .capa__padding {
        padding: 8rem 2rem;
    }
}
