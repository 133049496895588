.carousel-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    background-color: var(--color-bg);
    /* border-radius: 25px; */
    overflow: hidden;
    padding: 2rem;
    vertical-align: bottom;
    /* border: 1px solid white; */
}

.carousel-item-texto {
    /* border: 1px solid white; */

    display: flex;
    flex: 0.8;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 1rem; */
}

.carousel-item-texto p {
    text-align: center;
    hyphens: auto;
    font-size: 22px;
    color: var(--color-sobre-capa);
    font-family: var(--font-family-roboto);
    white-space: normal;
}

.carousel-item-nome {
    /* border: 1px solid white; */

    display: flex;
    flex: 0.2;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 1rem 4rem; */
    border-top: 2px solid #887660;
}

.carousel-item-nome p {
    margin-top: 1rem;

    width: 100%;
    text-align: center;
    hyphens: auto;
    font-size: 16px;
    font-weight: 500;
    color: #887660;
    font-family: var(--font-family-roboto);
}

@media screen and (max-width: 800px) {
    .carousel-item-texto p {
        font-size: 19px;
    }

    .carousel-item-nome p {
        font-size: 15px;
    }
}


@media screen and (max-width: 550px) {
    .carousel-item-texto p {
        font-size: 16px;
    }

    .carousel-item-nome p {
        font-size: 14px;
    }

    .carousel-item-texto {
        flex: 0.9;
    }

    .carousel-item-nome {
        flex: 0.1;
    }
}

@media screen and (max-width: 350px) {
    .carousel-item-texto p {
        font-size: 15px;
    }
}

@media screen and (max-width: 300px) {
    .carousel-item-texto p {
        font-size: 14px;
    }

    .carousel-item-nome p {
        font-size: 12px;
    }
}