.icone-esq {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 115px;
}

.icone-esq {
    margin-left: -57.5px;
}