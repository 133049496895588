.site__sobre {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-fundo-secao);
    overflow: hidden;
}

.site__sobre-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    max-width: 100%;
    width: 500px;
    height: auto;
}

.site__sobre-img img {
    border-radius: 15px;
    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); */
}

.site__sobre-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    margin-left: 4rem;
}

.site__sobre-content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.site__sobre-content_text h2 {
    /* display: flex;
    align-items: center; */
    margin: 1rem 0;
    font-size: 40px;
    color: var(--color-bg);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-weight: 700;
    font-family: var(--font-family-title);
}

.site__sobre-content_text h6 {
    margin: 10px 0;
    font-size: 20px;
    color: var(--color-bg);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-family: var(--font-family-title);
}

.site__sobre-content_text p {
    text-align: justify;
    hyphens: auto;
    margin: 10px 0;
    font-size: 18px;
    color: var(--color-bg);
    font-family: var(--font-family-roboto);
}

.site__sobre-content_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

@media screen and (max-width: 1150px) {
    .site__sobre-img {
        width: 400px;
    }
}

@media screen and (max-width: 1050px) {
    .site__sobre-img {
        width: 350px;
    }
}

@media screen and (max-width: 900px) {
    .site__sobre {
        flex-direction: column;
    }

    .site__sobre-content {
        margin: 0;
    }

    .site__sobre-img img {
        width: 350px;
    }

    .site__sobre-content_text {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .site__sobre-img img {
        width: 70%;
    }

    .site__sobre-content_text h2 {
        font-size: 30px;
    }

    .site__sobre-content_text h6 {
        font-size: 18px;
    }
    
    .site__sobre-content_text p {
        font-size: 16px;
    }
}
