.site__acad {
    background-color: var(--color-fundo-secao);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.linha-vertical-acad {
    margin-top: 90px;
    width: 2px;
    background-color: var(--color-bg);
    height: 75%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.topo {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    /* Adicione mais estilos conforme necessário */
  }
  
  .parte-inferior {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* Adicione mais estilos conforme necessário */
  }

/* .linha-container {
    border: 1px solid white;
} */

.site__acad-titulo {
    margin-bottom: 4rem;
}

.site__acad-titulo h2 {
    color: var(--color-bg);
    font-size: 36px;
    text-align: center;
}

.item-esquerda-acad {
    display: flex;
}

.card-acad {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 750px) {
    .site__acad-titulo h2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 550px) {
    .linha-vertical-acad {
        height: 80%;
    }
}

/* @media screen and (max-width: 390px) {
    .linha-vertical-acad {
        margin-top: 150px;
    }
} */