.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    transition: opacity 0.3s ease, filter 0.3s ease;
}

.whatsapp-button img {
    width: 50px;
    height: auto;
    border: none;
}

.whatsapp-button-fade {
    opacity: 0;
    pointer-events: none;
}

.whatsapp-button:hover {
    filter: brightness(0.8);
}

@media screen and (max-width: 800px) {
    .whatsapp-button:hover {
        filter: none;
    }
}