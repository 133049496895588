.site__espec {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
    height: 300px;
    cursor: pointer;
}

.site__espec h4 {
    margin: 8px 0;
    font-family: var(--font-family-title);
    font-size: 20px;
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-bg);
}

.site__espec-img img {
    width: 100%;
    height: auto;
    transition: filter 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.site__espec-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.site__espec-topicos {
    /* padding-left: 26px;
    padding-right: 4px; */
    padding: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    transform: translateY(0);
    text-align: center;
    hyphens: auto;

    height: 222px;
    display: flex;
    flex: column;
    justify-content: center;
    align-items: center;
}

.site__espec-topicos li {
    font-size: 15px;
    color: var(--color-sobre-capa);
    font-family: var(--font-family-roboto);
    margin-top: 4px;
    list-style: none;
}

.sombreamento {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #560E0F;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.site__espec:hover .sombreamento {
    opacity: 0.5;
}

.site__espec:hover img {
    filter: brightness(50%) blur(3px);
}

.site__espec:hover .site__espec-topicos {
    transform: translateY(-100%);
}

/* @media screen and (max-width: 1100px) {
    .site__espec {
        width: 300px;
    }
} */

@media screen and (max-width: 450px) {
    .site__espec {
        width: 300px;
        height: 250px;
    }

    .site__espec-topicos {
        height: 180px;
    }

    .site__espec-topicos li {
        font-size: 14px;
        margin-top: 4px;
    }
}

@media screen and (max-width: 310px) {
    .site__espec {
        width: 250px;
        height: 200px;
    }

    .site__espec-topicos {
        height: 134px;
    }

    .site__espec-topicos li {
        font-size: 12px;
        margin-top: 0;
    }
}