.depoimentos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url('../../assets/depoimentos-bg.webp');
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.65);
}

.depoimentos-titulo {
    margin-bottom: 2rem;
}

.depoimentos-titulo h2 {
    font-size: 40px;
    color: var(--color-font-prof);
    /* text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.5); */
    font-weight: 600;
    font-family: var(--font-family-title);
    margin-bottom: 3rem;
}

.carousel {
    overflow: hidden;
    width: 700px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 25px;
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
    border-radius: 25px;
    
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); */
}

.carousel-buttons {
    display: flex;
    justify-content: space-evenly;
}

.carousel-buttons > button {
    color: var(--color-font-prof);
    transition: color 0.3s ease-in-out;
}

.carousel-buttons > button:hover {
    color: #887660;
}

.button-arrow {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 2rem;
}

.indicators {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

.indicator-buttons {
    border: none;
    cursor: pointer;
}

.indicators > button {
    margin: 5px;
    background: none;
}

.indicator-symbol {
    color: var(--color-font-prof);
    transition: color 0.3s ease-in-out;
}

.indicator-symbol:hover {
    color: #887660;
}

.indicator-symbol-active {
    color: #887660;
}

@media screen and (max-width: 450px) {
    .depoimentos-titulo h2 {
        font-size: 30px;
    }

    .indicators {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@media screen and (max-width: 300px) {
    .indicators {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}