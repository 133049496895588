.site__expProfDir {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.site__expProfDir-content {
    border: 1px solid var(--color-font-navbar);
    border-radius: 5px;
    padding: 1rem;
    width: 400px;
}

.site__expProfDir-content h6 {
    text-align: center;
    font-family: var(--font-family-title);
    font-size: 24px;
    line-height: 24px;
    color: var(--color-font-prof);
    margin-bottom: 1rem;
    letter-spacing: 2px;
}

.site__expProfDir-content p {
    text-align: center;
    hyphens: auto;
    font-family: var(--font-family-roboto);
    font-size: 16px;
    color: var(--color-font-prof);
    border-top: 1px solid var(--color-font-navbar);
    border-bottom: 1px solid var(--color-font-navbar);
    padding: 10px;
}

.site__expProfDir-seta {
    position: relative;
    border-top: 1px solid var(--color-font-navbar);
    width: 100%;
    height: 10px;

    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--color-font-navbar);
        border-radius: 50%;
        top: 0;
        right: -10px;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 1300px) {
    .site__expProfDir {
        width: 450px;
    }
}

@media screen and (max-width: 1200px) {
    .site__expProfDir {
        width: 350px;
    }
}

@media screen and (max-width: 1000px) {
    .site__expProfDir {
        width: 300px;
        margin-left: 6rem;
    }
}

@media screen and (max-width: 850px) {
    .site__expProfDir {
        width: 250px;
    }

    .site__expProfDir-content h6 {
        font-size: 21px;
        line-height: 21px;
    }
}

@media screen and (max-width: 750px) {
    .site__expProfDir-content {
        width: 200px;
        padding: 0.5rem;
    }

    .site__expProfDir-content h6 {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 0.5rem;
    }

    .site__expProfDir-content p {
        font-size: 14px;
        padding: 8px;
    }

    .site__expProfDir {
        margin-left: 0;
        margin-bottom: 1rem;
        width: 300px;
    }

    .site__expProfDir-seta {
        width: 40%;
    }
}

@media screen and (max-width: 330px) {
    .site__expProfDir-seta {
        width: 25%;
    }

    .site__expProfDir-content {
        width: 175px;
    }
}