.site__duvida {
    width: 100%;
}

.site__duvida .site__duvida-content {
    position: relative;
}

.site__duvida-content_title {
    border-top: 1px solid var(--color-font-navbar);
}

.first-item-style {
    border-top: 0;
}

.site__duvida .site__duvida-content_title {
    display: flex;
    align-items: center;
}

.site__duvida .site__duvida-content .site__duvida-content_title {
    position: relative;
    padding: 10px;
    cursor: pointer;
    height: 60px;
    background-color: var(--color-font-prof);
    color: var(--color-bg);

    font-family: var(--font-family-title);
    font-size: 20px;
}

.site__duvida .site__duvida-content 
.site__duvida-content_title::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.site__duvida-content_text p {
    padding: 10px;

    font-size: 18px;
    font-family: var(--font-family-roboto);
    color: var(--color-bg);
}

.site__duvida .site__duvida-content .site__duvida-content_text {
    position: relative;
    background-color: var(--color-fundo-secao);
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    text-align: justify;
    hyphens: auto;

    max-height: 0;
    opacity: 0;
}

.site__duvida.active .site__duvida-content .site__duvida-content_text {
    max-height: 150px;
    opacity: 1;
}

.site__duvida-content_text-container {
    border-top: 1px solid var(--color-font-navbar);
}

.site__duvida .site__duvida-content_title {
    &:hover {
        background-color: #caaa82;
        transition: background-color 0.3s ease-in-out;
    }
}

@media screen and (max-width: 800px) {
    .site__duvida-content_title h3 {
        font-size: 16px;
    }

    .site__duvida-content_text p {
        font-size: 16px;
    }
}

@media screen and (max-width: 750px) {
    .site__duvida.active .site__duvida-content .site__duvida-content_text {
        max-height: 450px;
    }
}

/* @media screen and (max-width: 450px) {
    .site__duvida-content_title h3 {
        font-size: 16px;
    }

    .site__duvida-content_text p {
        font-size: 16px;
    }
} */