.site__navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background-color: var(--color-bg);
    z-index: 100;

    padding: 0.5rem 3rem;
}

.navbar-shadow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-no-shadow {
    box-shadow: none;
}

.site__navbar-links {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
}

.site__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1.5;
}

.site__navbar-links_icons,
.site__navbar-links_idiomas {
    padding-left: 1rem;
}

.site__navbar-menu {
    display: none;
}

.site__navbar-links_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.site__navbar-links_mobile {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -300;
}

.mobile-container-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.site__navbar-links_idiomas-mobile {
    margin-left: 2rem;
}

.mobile-container-bottom {
    display: none;
    /* margin-top: 10px; */
    justify-content: center;
    align-items: center;
}

.mobile-logo-simbolo,
.mobile-logo-nome {
    display: none;
}

.mobile-logo-simbolo img {
    width: 110px;
    height: auto;
}

/* .mobile-logo-nome img {
    width: 100px;
    height: auto;
} */

.mobile-bottom-links {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */
}

.site__navbar-links_icons-medio {
    display: none;
    margin-left: 3rem;
}

@media screen and (max-width: 1265px) {
    .site__navbar-links_icons {
        display: none;
    }
}

@media screen and (max-width: 1175px) {
    .site__navbar-links {
        display: none;
    }

    .site__navbar-menu,
    .site__navbar-links_mobile {
        display: flex;
    }

    .site__navbar-menu .site__navbar-links_icons {
        display: flex;
    }

    .site__navbar-links_icons-medio {
        display: flex;
    }
}

@media screen and (max-width: 600px) {
    .site__navbar-links_idiomas-mobile {
        display: none;
    }

    .mobile-container-bottom {
        display: flex;
    }

    .site__navbar {
        padding: 1rem;
    }

    .site__navbar-links_logo-mobile {
        display: none;
    } 

    .site__navbar-links_mobile {
        flex-direction: row;
    }

    .mobile-logo-simbolo,
    .mobile-logo-nome {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-logo-simbolo-img img {
        width: 50px;
        margin-right: 1rem;
    }

    .mobile-container-bottom {
        flex-direction: column;
        margin-left: 1rem;
    }

    .mobile-bottom-links {
        display: flex;
    }

    .site__navbar-links_icons-medio {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .mobile-logo-simbolo img {
        width: 95px;
        height: auto;
    }

    .mobile-logo-simbolo-img img {
        width: 45px;
        margin-right: 0.5rem;
    }
}

/* @media screen and (max-width: 330px) {
    .mobile-logo-simbolo-img img {
        display: none;
    }
} */

@media screen and (max-width: 315px) {
    /* .mobile-logo-simbolo img {
        width: 60px;
        height: auto;
    } */
    
    /* .mobile-logo-nome img {
        width: 140px;
        height: auto;
    } */

    .site__navbar-links_icons-mobile {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .mobile-logo-simbolo-img img {
        display: none;
    }
}
