.site__acadEsq {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.site__acadEsq-desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    margin-right: 1.5rem;
}

.site__acadEsq-desc p {
    text-align: end;
    hyphens: auto;
    font-family: var(--font-family-roboto);
    font-size: 16px;
    color: var(--color-bg);
}

.site__acadEsq-titulo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-left: 1.5rem;
    /* text-align: start; */
}

.site__acadEsq-titulo h6 {
    font-family: var(--font-family-title);
    font-size: 24px;
    line-height: 24px;
    color: var(--color-bg);
    letter-spacing: 2px;
    text-align: start;
}

@media screen and (max-width: 750px) {
    .site__acadEsq-titulo h6 {
        font-size: 18px;
        line-height: 18px;
    }

    .site__acadEsq-desc p {
        font-size: 14px;
    }
}

@media screen and (max-width: 350px) {
    .site__acadEsq-titulo h6 {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
    }
}