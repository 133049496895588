.site__contatos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.site__contatos img {
    width: 36px;
    height: auto;
    margin: 0 0.5rem; 
    transition: filter 0.3s ease-in-out;
}

.site__contatos img:hover {
    filter: brightness(0.7);
}

@media screen and (max-width: 550px) {
    .site__contatos img {
        width: 30px;
    }
}