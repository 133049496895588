@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --font-family-roboto: 'Roboto', sans-serif;
    --font-family-title: 'Exo', sans-serif;

    --color-bg: #560E0F;
    --color-font-navbar: rgb(161, 134, 100);
    --color-font-capa: rgb(225, 182, 130);
    --color-sobre-capa: rgb(245, 241, 195);
    --color-1: rgb(107, 89, 67);
    --color-fundo-secao: rgb(240, 217, 189);
    --color-fundo-espec: #FAFAFA;
    --color-border-espec: #754F40;
    --color-font-prof: #E1B682;
}