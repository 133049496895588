.site__logo,
.site__logo-simbolo,
.site__logo-nome {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.site__logo {
    background-color: var(--color-bg);
}

.site__logo-simbolo img {
    width: 60px;
    height: auto;
}

.site__logo-nome img {
    width: 175px;
    height: auto;
    margin-left: 1rem;
}

@media screen and (max-width: 1155px) {
    .site__logo-simbolo img {
        width: 50px;
    }
    
    .site__logo-nome img {
        width: 150px;
    }
}
